import natura from "./img/natura_siberica.png"
import organic from "./img/organic.png"
import rostics from "./img/rostics.png"
import citylink from "./img/cityLink.png"
import familia from "./img/familia.png"
import goodSvyaz from "./img/good_svyaz.jpg"
import ildebote from "./img/ildebote.png"
import kari from "./img/kari.jpg"
import lentaOnlain from "./img/lenta_onlain.jpg"
import sokolov from "./img/sokolov.jpg"
import yandexEda from "./img/yandex_eda.png"
import ylibkaRadugi from "./img/ylibka_radugi.png"

export const partnersMain = [
  {
    id: 31,
    brand: "ВсеИнструменты.ру",
    icon_url: "https://api.halvacard.ru/public-api/files/abe93c75-6f27-4c3f-83f6-9c36b9687ddc.jpeg",
    urlName: "vseinstrumenti",
  },
  {
    id: 32,
    brand: "SOKOLOV",
    icon_url: sokolov,
    urlName: "sokolov",
  },
  {
    id: 33,
    brand: "natura_siberica",
    icon_url: natura,
    urlName: "natura_siberica",
  },
  {
    id: 34,
    brand: "organic",
    icon_url: organic,
    urlName: "organic",
  },
  {
    id: 35,
    brand: "flowwow",
    icon_url: "https://api.halvacard.ru/public-api/files/0a8a9f9f-4e78-4ce9-9e22-ad9d3a747055.png",
    urlName: "flowwow",
  },
  {
    id: 36,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 37,
    brand: "ЛУКОЙЛ",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
  },
  {
    id: 8363,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 253,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta-onlajn",
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
  },
  {
    id: 1654,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ulybka-radugi",
  },
  {
    id: 7733,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "citilink",
  },
]

export const partnersRostics = [
  {
    id: 31,
    brand: "rostics",
    icon_url: rostics,
    urlName: "rostics",
  },
  {
    id: 32,
    brand: "SOKOLOV",
    icon_url: sokolov,
    urlName: "sokolov",
  },
  {
    id: 33,
    brand: "natura_siberica",
    icon_url: natura,
    urlName: "natura_siberica",
  },
  {
    id: 34,
    brand: "organic",
    icon_url: organic,
    urlName: "organic",
  },
  {
    id: 35,
    brand: "flowwow",
    icon_url: "https://api.halvacard.ru/public-api/files/0a8a9f9f-4e78-4ce9-9e22-ad9d3a747055.png",
    urlName: "flowwow",
  },
  {
    id: 36,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 37,
    brand: "ЛУКОЙЛ",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
  {
    id: 38,
    brand: "ВсеИнструменты.ру",
    icon_url: "https://api.halvacard.ru/public-api/files/abe93c75-6f27-4c3f-83f6-9c36b9687ddc.jpeg",
    urlName: "vseinstrumenti",
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
  },
  {
    id: 8363,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 253,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta-onlajn",
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
  },
  {
    id: 1654,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ulybka-radugi",
  },
  {
    id: 7733,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "citilink",
  },
]

export const partnersZoon = [
  {
    id: 31,
    brand: "ВсеИнструменты.ру",
    icon_url: "https://api.halvacard.ru/public-api/files/abe93c75-6f27-4c3f-83f6-9c36b9687ddc.jpeg",
    urlName: "vseinstrumenti",
  },
  {
    id: 32,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ylibka_radugi",
  },
  {
    id: 33,
    brand: "ozon-ru",
    icon_url: "https://api.halvacard.ru/public-api/files/24983b4b-9d5e-40c4-8dd6-6349d436eec4.jpeg",
    urlName: "ozon-ru",
  },
  {
    id: 34,
    brand: "yandex-market",
    icon_url: "https://api.halvacard.ru/public-api/files/6287d5ab-6607-49a6-9b94-edce6c0e9b73.jpeg",
    urlName: "yandex-market",
  },
  {
    id: 35,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 36,
    brand: "ЛУКОЙЛ",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
  },
  {
    id: 8363,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 253,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta-onlajn",
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
  },
  {
    id: 7733,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "citilink",
  },
]

export const lenta = [
  {
    id: 31,
    brand: "Лента",
    icon_url: "https://api.halvacard.ru/public-api/files/88dcbbdf-947d-4741-9597-3f69ab9fe4a0.jpeg",
    urlName: "lenta",
  },
  {
    id: 32,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta_online",
  },
  {
    id: 33,
    brand: "SOKOLOV",
    icon_url: sokolov,
    urlName: "sokolov",
  },
  {
    id: 34,
    brand: "natura_siberica",
    icon_url: natura,
    urlName: "natura_siberica",
  },
  {
    id: 35,
    brand: "organic",
    icon_url: organic,
    urlName: "organic",
  },
  {
    id: 36,
    brand: "flowwow",
    icon_url: "https://api.halvacard.ru/public-api/files/0a8a9f9f-4e78-4ce9-9e22-ad9d3a747055.png",
    urlName: "flowwow",
  },
  {
    id: 37,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 38,
    brand: "ЛУКОЙЛ",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
  },
  {
    id: 8363,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
  },
  {
    id: 1654,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ulybka-radugi",
  },
  {
    id: 7733,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "citilink",
  },
]

export const autoPromo = [
  {
    id: 31,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 32,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 33,
    brand: "kari KIDS",
    icon_url: "https://api.halvacard.ru/public-api/files/9f577c4c-ed16-4ba3-83d6-53d9c4d7d5a1.png",
    urlName: "kari-kids-16935838",
  },
  {
    id: 34,
    brand: "askona",
    icon_url: "https://api.halvacard.ru/public-api/files/38c29e64-8e97-44c8-9c6b-63bedb4cdd6b.png",
    urlName: "askona",
  },
  {
    id: 35,
    brand: "ostin",
    icon_url: "https://api.halvacard.ru/public-api/files/f2a50240-5d9a-4839-ab37-9058506e664a.png",
    urlName: "ostin",
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
  },
  {
    id: 253,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta-onlajn",
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
  },
  {
    id: 1654,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ulybka-radugi",
  },
  {
    id: 7733,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "citilink",
  },
]

export const blackFriday = [
  {
    id: 31,
    brand: "yandex-market",
    icon_url: "https://api.halvacard.ru/public-api/files/6287d5ab-6607-49a6-9b94-edce6c0e9b73.jpeg",
    urlName: "yandex-market",
  },
  {
    id: 32,
    brand: "magnit-kosmetik",
    icon_url: "https://api.halvacard.ru/public-api/files/fdf3ac12-016c-4efd-ac5f-606d811ac27b.jpeg",
    urlName: "magnit-kosmetik",
  },
  {
    id: 33,
    brand: "hoff",
    icon_url: "https://api.halvacard.ru/public-api/files/59aebcf2-bd41-413c-8f36-c759f2d481a8.png",
    urlName: "hoff",
  },
  {
    id: 34,
    brand: "obi",
    icon_url: "https://api.halvacard.ru/public-api/files/85539422-b9d3-49ab-b346-20147d71d9ba.png",
    urlName: "obi",
  },
  {
    id: 35,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "cityLink",
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
  },
  {
    id: 8363,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 253,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta-onlajn",
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
  },
  {
    id: 1654,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ulybka-radugi",
  },
]

export const newYearPartners = [
  {
    id: 31,
    brand: "zolla",
    icon_url: "	https://api.halvacard.ru/public-api/files/f5c870c0-03a1-4362-ad3c-f6ddde46aa73.jpeg",
    urlName: "zolla",
  },
  {
    id: 8363,
    brand: "kari",
    icon_url: kari,
    urlName: "kari",
  },
  {
    id: 33,
    brand: "komus",
    icon_url: "https://api.halvacard.ru/public-api/files/f3114e07-0d3f-4122-81c3-6e43d5955513.jpeg",
    urlName: "komus",
  },
  {
    id: 34,
    brand: "candy",
    icon_url: "https://api.halvacard.ru/public-api/files/5376d2c8-4580-4cf5-805a-327e7ad241e6.jpeg",
    urlName: "candy",
  },
  {
    id: 35,
    brand: "usmall",
    icon_url: "https://api.halvacard.ru/public-api/files/b770b8dd-e553-4892-b1cb-0fa687da2ae4.jpeg",
    urlName: "usmall",
  },
  {
    id: 36,
    brand: "rif_gosh",
    icon_url: "https://api.halvacard.ru/public-api/files/f257db49-1b0b-49c3-96b2-a7ce71f7400f.png",
    urlName: "rif_gosh",
  },
  {
    id: 37,
    brand: "divan_ru",
    icon_url: "https://api.halvacard.ru/public-api/files/95764a83-cc2e-4449-b189-7801fcafcb6d.jpeg",
    urlName: "divan_ru",
  },
  {
    id: 8619,
    brand: "sunlight",
    icon_url: "https://api.halvacard.ru/public-api/files/8a3c2a77-9cd1-4e23-af59-cd4fd3affd2f.jpeg",
    urlName: "sunlight",
  },
]
